import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'
import { populateIndex } from '../../../helpers/generalHelpers'

/**
 * Add index to data.
 *
 * @param {Object[]} data recommendation and notes
 * @return {Object[]} result
 */
function populateData(data) {
    let result = []
    if (!data) return result
    result = populateIndex(data)
    return result
}

/**
 * Add supplement item to patient's supplement list.
 * for patient with @param patientId.
 *
 * @param {String} patientId Patient's Firebase id
 * @return {Promise<any>}
 */
const getSignedUrl = (documentURL) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ accessToken }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                const params = { documentURL }

                axios.put(
                    `${baseUrl}/patients/getSecureFile`,
                    params,
                    reqBody
                )
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default getSignedUrl