import React, { useState, useEffect, Suspense, lazy, useCallback } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as ROUTES from './constants/routes'
import masterAccounts from './pages/LibraryCourses/masterAccounts'

// React components
import Grid from '@material-ui/core/Grid'
import PrivateRoute from './components/PrivateRoute'
import FeedbackButton from './elements/buttons/FeedbackButton'
import LoadingSpinner from './elements/LoadingSpinner'
import NewInvitation from './components/Invitations/newInvitation'
import Tutorial from './components/Tutorial'

// Contexts: To enable information-sharing between siblings
import BusinessContext from './contexts/BusinessContext'
import EnablePlanContext from './contexts/EnablePlanContext'
import PatientsContext from './contexts/PatientsContext'
import SalesContext from './contexts/SalesContext'
const BusinessProvider = BusinessContext.Provider
const EnablePlanProvider = EnablePlanContext.Provider
const PatientsProvider = PatientsContext.Provider
const SalesProvider = SalesContext.Provider

// Cognito Auth Functions
import useAppData from './hooks/useAppData'
import { makeStyles } from '@material-ui/core'
import ResponsiveDrawerMenu from './components/ResponsiveDrawerMenu'

// Pages
const Account = lazy(() => import('./pages/Account'))
const Business = lazy(() => import('./pages/Business'))
// const Chat = lazy(() => import('./pages/Chat'))
const Chat = lazy(() => import('./pages/ChatService'))
const Error = lazy(() => import('./pages/Error'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const Fullscript = lazy(() => import('./pages/Fullscript'))
const Invitations = lazy(() => import('./pages/Invitations'))
const Landing = lazy(() => import('./pages/Landing'))
const Patients = lazy(() => import('./pages/Patients'))
const Programs = lazy(() => import('./components/Programs/Programs'))
const LandingPage = lazy(() => import('./pages/LandingPage'));
const UserLandingPage = lazy(() => import('./pages/userLandingPage'));
const Requests = lazy(() => import('./pages/Requests'))
const Signin = lazy(() => import('./pages/Signin'))
const Signup = lazy(() => import('./pages/Signup'))
const SignupClinic = lazy(() => import('./pages/SignupClinic'))
const SignupSuccessful = lazy(() => import('./pages/Signup_successful'))
const CoursesLibrary = lazy(() => import('./pages/LibraryCourses'))
const CourseSass = lazy(() => import('./pages/CourseSass'))
const IntakeForm = lazy(() => import('./pages/IntakeForm'))
const CourseSassSuccess = lazy(() => import('./pages/CourseSassSuccess'))
const CourseSassFailed = lazy(() => import('./pages/CourseSassFailed'))
const UserNotification = lazy(() => import('./pages/Notifications'))
const Protocol = lazy(() => import('./pages/Protocol'))
const LabForAll = lazy(() => import('./pages/LabForAll'))

//Clinic Manager
const ClinicManager = lazy(() => import('./pages/ClinicManager'))
const ClinicPractitionerManager = lazy(() => import('./pages/ClinicPractitionerManager'))
const ClinicClientList = lazy(() => import('./pages/ClinicClientList'))
const ClinicResources = lazy(() => import('./pages/ClinicResources'))
const ClinicManageUser = lazy(() => import('./pages/ClinicManageUser'))

import { change_sign_in_status } from './lib/Api/Practitioner'
import ClinicInvitesPage from './pages/ClinicInvitesPage'

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'none',

        [theme.breakpoints.down('768')]: {
            display: 'block',
        },
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        marginBottom: '10%',
        padding: theme.spacing(6),
        [theme.breakpoints.down('340')]: {
            padding: '10px'
        },

    },
    content2: {

    }
}))

function App() {


    if (process.env.NODE_ENV === 'production') {
        console.log = () => { };
    }


    const classes = useStyles()
    const {
        hasIncomingInvitation,
        setHasIncomingInvitation,
        state,
        name,
        email,
        setAllData,
        setFirstLogin,
        setIsAuth,
        setPatients,
        setStripeId,
        setSubsPkgs,
        setCourseSASS,
        setShownTutorial,
        setUnrespondedReqCount,
        decrementReqCount,
        renderPatients,
        renderArchivedPatients,
        renderSubsPkgs,
        getArchivedPatients,
        fetchPatientData,
        setNotificationView
    } = useAppData()

    const {
        id,
        enBd,
        firstLogin,
        isAuth,
        isFull,
        remainingSeats,
        patients,
        patientData,
        patientList,
        archived,
        locked,
        stripeId,
        subsPkgs,
        subscription,
        unrespondedReqCount,
        courseSASS,
        mailchimp,
        invitationsNotified,
        invitations,
        shownTutorial,
        shownNotification
    } = state

    console.log('state', state)
    console.log('shownTutorial', shownTutorial)


    useEffect(() => {
        // Define the gtag() function
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }

        // Initialize gtag.js
        gtag('js', new Date());

        // Configure the Google Ads tracking
        gtag('config', 'AW-11311171064'); // Replace with your actual tracking ID
    }, []);




    const validateAuth = useCallback(() => setIsAuth(), [])
    const signOutApp = useCallback(() => {
        setIsAuth()
        setPatients([])
        setSubsPkgs([])
    }, [])
    const handleFirstLogin = useCallback(
        () => setFirstLogin(false),
        []
    )
    const updateStripeId = (stripeId) => setStripeId(stripeId)
    const updateSubsPkgs = (newList) => {
        if (newList) setSubsPkgs(newList)
        else renderSubsPkgs()
    }
    const updatePatientList = useCallback(() => renderPatients(), [])
    const updateReqCount = (reqCount) => setUnrespondedReqCount(reqCount);
    const [isLanding, setIslanding] = useState(false)


    let sideBarItems = []

    if (state.clinicProfile === true) {
       

        sideBarItems = [
            { text: 'CLINIC MANAGER', route: ROUTES.CLINIC_MANAGER },
            { text: 'My Clients', route: ROUTES.PATIENTS },
            { text: 'CHAT', route: ROUTES.CHAT_SERVICE },
            {
                text: 'Notifications',
                route: ROUTES.NOTIFICATION,
                showDot: shownNotification
            },
            { text: 'PROGRAMS', route: ROUTES.PROGRAMS },
            { text: 'Public Links', route: ROUTES.LANDING_FORM },
            { text: 'COURSES LIBRARY', route: ROUTES.LIBRARY },
            { text: 'Forms', route: ROUTES.INTAKE_FORM },
            { text: 'Protocols', route: ROUTES.PROTOCOL },
            {
                text: 'REQUESTS',
                route: ROUTES.REQUESTS,
                showDot: unrespondedReqCount > 0,
            },
            {
                text: 'ADD NEW CLIENT',
                route: ROUTES.INVITATIONS,
                showStarterTag: !isFull,
            },
            { text: 'BUSINESS MANAGER', route: ROUTES.BUSINESS },
            { text: 'ACCOUNT', route: ROUTES.ACCOUNT }
        ]

    } else {

        sideBarItems = firstLogin
            ? [{ text: 'ACCOUNT', route: ROUTES.ACCOUNT }]
            :
            masterAccounts.includes(id) ?
                [{ text: 'My Clients', route: ROUTES.PATIENTS },
                { text: 'CHAT', route: ROUTES.CHAT_SERVICE },
                {
                    text: 'Notifications',
                    route: ROUTES.NOTIFICATION,
                    showDot: shownNotification
                },
                { text: 'Files', route: ROUTES.FILES },
                { text: 'PROGRAMS', route: ROUTES.PROGRAMS },
                { text: 'Public Links', route: ROUTES.LANDING_FORM },
                { text: 'COURSES LIBRARY', route: ROUTES.LIBRARY },
                { text: 'Forms', route: ROUTES.INTAKE_FORM },
                { text: 'Protocols', route: ROUTES.PROTOCOL },
                {
                    text: 'REQUESTS',
                    route: ROUTES.REQUESTS,
                    showDot: unrespondedReqCount > 0,
                },
                {
                    text: 'ADD NEW CLIENT',
                    route: ROUTES.INVITATIONS,
                    showStarterTag: !isFull,
                },
                { text: 'BUSINESS MANAGER', route: ROUTES.BUSINESS },
                { text: 'ACCOUNT', route: ROUTES.ACCOUNT }
                ] :
                [
                    { text: 'My Clients', route: ROUTES.PATIENTS },
                    { text: 'CHAT', route: ROUTES.CHAT_SERVICE },
                    {
                        text: 'Notifications',
                        route: ROUTES.NOTIFICATION,
                        showDot: shownNotification
                    },
                    { text: 'PROGRAMS', route: ROUTES.PROGRAMS },
                    { text: 'Public Links', route: ROUTES.LANDING_FORM },
                    { text: 'COURSES LIBRARY', route: ROUTES.LIBRARY },
                    { text: 'Forms', route: ROUTES.INTAKE_FORM },
                    { text: 'Protocols', route: ROUTES.PROTOCOL },
                    {
                        text: 'REQUESTS',
                        route: ROUTES.REQUESTS,
                        showDot: unrespondedReqCount > 0,
                    },
                    {
                        text: 'ADD NEW CLIENT',
                        route: ROUTES.INVITATIONS,
                        showStarterTag: !isFull,
                    },
                    { text: 'BUSINESS MANAGER', route: ROUTES.BUSINESS },
                    { text: 'ACCOUNT', route: ROUTES.ACCOUNT }
                ]

        if (state?.clinicRequests && state?.clinicRequests?.length) {
            sideBarItems.splice(1, 0, { text: 'CLINIC INVITE', route: ROUTES.CLINIC_INVITATIONS });
        }

    }

    return (
        <Router>
            <div style={{ display: 'flex' }}>
                {isAuth && (
                    <ResponsiveDrawerMenu
                        list={sideBarItems}
                        signOutApp={signOutApp}
                        isFirstLogin={firstLogin}
                    />
                )}
                <main className={classes.content} id="main-content-wrapper">
                    {
                        hasIncomingInvitation
                        &&
                        <NewInvitation
                            open={hasIncomingInvitation}
                            onClose={() => {
                                setHasIncomingInvitation(!hasIncomingInvitation)
                            }}
                            practitionerId={id}
                            invitationsNotified={invitationsNotified}
                        />
                    }
                    {shownTutorial
                        &&
                        <Tutorial
                            name={name}
                            onClose={async () => {
                                await change_sign_in_status()
                                setShownTutorial(!shownTutorial)
                            }}
                        />
                    }


                    <div className={classes.toolbar} />
                    <Suspense fallback={<LoadingSpinner />}>
                        <Switch>
                            {/* Public Routes */}
                            <Route
                                exact
                                path={ROUTES.LANDING}
                                render={() => (
                                    <Landing isAuthenticated={isAuth} />
                                )}
                            />
                            <Route
                                path={ROUTES.SIGN_IN}
                                render={() => (
                                    <Signin
                                        isAuthenticated={isAuth}
                                        handleAuthentication={validateAuth}
                                    />
                                )}
                            />
                            <Route
                                path={ROUTES.SIGN_UP_SUCCESSFUL}
                                render={() => (
                                    <SignupSuccessful isAuthenticated={isAuth}
                                        handleAuthentication={validateAuth} />
                                )}
                            />
                            {!isAuth && (
                                <Route
                                    path={ROUTES.SIGN_UP}
                                    render={() => (
                                        <Signup isAuthenticated={isAuth} />
                                    )}
                                />
                            )}

                            {!isAuth && (
                                <Route
                                    path={ROUTES.SIGN_UP_CLINIC}
                                    render={() => (
                                        <SignupClinic isAuthenticated={isAuth} />
                                    )}
                                />
                            )}
                            <Route
                                path={ROUTES.PASSWORD_FORGET}
                                render={() => (
                                    <ForgotPassword isAuthenticated={isAuth} />
                                )}
                            />

                            {/* Private Routes */}
                            <PrivateRoute
                                path={ROUTES.CLINIC_PRACTIONERS_MANAGER}
                                component={() => (
                                    <ClinicPractitionerManager globalState={state} isAuth={isAuth} />
                                )
                                }

                            />

                            {/* Private Routes */}
                            <PrivateRoute
                                path={ROUTES.CLINIC_CLIENT_LIST}
                                component={() => (
                                    <ClinicClientList globalState={state} isAuthenticated={isAuth} />
                                )
                                }

                            />

                            <PrivateRoute
                                exact
                                path={ROUTES.CLINIC_MANAGER}
                                component={() => (
                                    <ClinicManager globalState={state} isAuthenticated={isAuth} />
                                )
                                }

                            />
                           

                            <PrivateRoute
                                exact
                                path={ROUTES.CLINIC_MANAGER_USER}
                                component={() => (
                                    <ClinicManageUser globalState={state} isAuthenticated={isAuth} />
                                )
                                }

                            />
                            
                            <PrivateRoute
                                exact
                                path={ROUTES.CLINIC_RESOURCES}
                                component={() => (
                                    <ClinicResources globalState={state} isAuthenticated={isAuth} />
                                )
                                }

                            />

                            <PrivateRoute
                                path={ROUTES.ACCOUNT}
                                component={() => (
                                    <EnablePlanProvider value={courseSASS}>
                                        <Account
                                            handleAuthentication={validateAuth}
                                            handleFirstLoginState={handleFirstLogin}
                                            subsPlanId={subscription.plan_id}
                                            enBd={enBd}
                                            globalState={state}
                                        />
                                    </EnablePlanProvider>
                                )
                                }

                            />

                            <PrivateRoute
                                path={ROUTES.BUSINESS}
                                component={() => (
                                    <BusinessProvider value={subsPkgs}>
                                        <SalesProvider value={stripeId}>
                                            <PatientsProvider
                                                value={patients}
                                            >
                                                <Business
                                                    updateSubsPackages={
                                                        updateSubsPkgs
                                                    }
                                                    updateStripeId={
                                                        updateStripeId
                                                    }
                                                />
                                            </PatientsProvider>
                                        </SalesProvider>
                                    </BusinessProvider>
                                )}
                            />


                            <PrivateRoute
                                path={ROUTES.CHAT_SERVICE}
                                component={() => (
                                    <PatientsProvider value={patients}>
                                        <Chat name={name} practitionerId={id} globalState={state} />
                                    </PatientsProvider>
                                )}
                            />



                            <PrivateRoute
                                path={ROUTES.LIBRARY}
                                component={() => (
                                    <PatientsProvider value={patients}>
                                        <CoursesLibrary
                                            stripeid={state.stripeId}
                                            courseSASS={courseSASS}
                                            archivedPatients={archived}
                                            lockedPatients={locked}
                                            state={state}
                                        />
                                    </PatientsProvider>
                                )}
                            />



                            <PrivateRoute
                                path={ROUTES.COURSES_SASS_SUCCESS}
                                component={() => (
                                    <>
                                        <CourseSassSuccess />
                                    </>
                                )}
                            />



                            <PrivateRoute
                                path={ROUTES.COURSES_SASS_FAILED}
                                component={() => (
                                    <>
                                        <CourseSassFailed />
                                    </>
                                )}
                            />

                            {
                                masterAccounts.includes(id)
                                &&
                                <PrivateRoute
                                    path={ROUTES.FILES}
                                    component={() => (
                                        <PatientsProvider
                                            value={patients}
                                        >
                                            <LabForAll />
                                        </PatientsProvider>
                                    )}
                                />}



                            <PrivateRoute
                                path={ROUTES.INTAKE_FORM}
                                component={() => (
                                    <PatientsProvider value={patients}>
                                        <IntakeForm
                                            name={name}
                                            subscription={state.intakeformSubscription}
                                            globalState={state}
                                        />
                                    </PatientsProvider>
                                )}
                            />



                            <PrivateRoute
                                path={ROUTES.PROTOCOL}
                                component={() => (
                                    <PatientsProvider value={patients}>
                                        <Protocol
                                            name={name}
                                        />
                                    </PatientsProvider>
                                )}
                            />



                            <PrivateRoute
                                path={ROUTES.COURSES_SASS}
                                component={() => (
                                    <>
                                        <CourseSass
                                            courseSASS={courseSASS}
                                            setCourseSASS={setCourseSASS}
                                        />
                                    </>
                                )}
                            />





                            <PrivateRoute
                                path={ROUTES.FULLSCRIPT}
                                component={() => (
                                    <Fullscript isAuth={isAuth} />
                                )}
                            />



                            <PrivateRoute
                                path={ROUTES.INVITATIONS}
                                component={() => (
                                    <BusinessProvider value={subsPkgs}>
                                        <EnablePlanProvider
                                            value={{
                                                planId: subscription.plan_id,
                                                isFull,
                                                remainingSeats,
                                            }}
                                        >
                                            <Invitations />
                                        </EnablePlanProvider>
                                    </BusinessProvider>
                                )}
                            />


                            <PrivateRoute
                                path={ROUTES.PATIENTS}
                                component={() => (
                                    <PatientsProvider value={patients}>
                                        <Patients
                                            name={name}
                                            setAllData={setAllData}
                                            oneToOneCourses={state.oneToOneCourses}
                                            updatePatientList={
                                                updatePatientList
                                            }
                                            getArchivedPatients={getArchivedPatients}
                                            remainingSeats={remainingSeats}
                                            isFull={isFull}
                                            patientList={patientList}
                                            archivedPatients={archived}
                                            lockedPatients={locked}
                                            updateArchivedList={
                                                renderArchivedPatients
                                            }
                                            isAuthenticated={isAuth}
                                            planId={subscription.plan_id}
                                            globalState={state}
                                            patientData={patientData}
                                            fetchPatientData={fetchPatientData}
                                            practitionerId={id}
                                        />
                                    </PatientsProvider>
                                )}
                            />


                            <PrivateRoute
                                path={ROUTES.PROGRAMS}
                                component={() => (
                                    <PatientsProvider value={patients}>
                                        <Programs
                                            archivedPatients={archived}
                                            remainingSeats={remainingSeats}
                                            ongoingYearlyPlan={
                                                isFull &&
                                                subscription.plan_id ===
                                                'A-V2'
                                            }
                                            setPatients={setPatients}
                                            globalState={state}


                                        />
                                    </PatientsProvider>
                                )}
                            />


                            <PrivateRoute
                                path={ROUTES.NOTIFICATION}
                                component={() => (
                                    <PatientsProvider value={patients}>
                                        <UserNotification
                                            practitionerId={id}
                                            setNotificationView={setNotificationView}
                                            shownNotification={shownNotification}
                                            globalState={state}

                                        />
                                    </PatientsProvider>
                                )}
                            />


                            <PrivateRoute
                                path={ROUTES.LANDING_FORM}
                                component={() => (
                                    <LandingPage mailchimp={mailchimp} practitionerId={id} />
                                )}
                            />

                            <PrivateRoute
                                path={ROUTES.CLINIC_INVITATIONS}
                                component={() => (
                                    <ClinicInvitesPage />
                                )}
                            />

                            {/* {!firstLogin && (
                                <PrivateRoute
                                    path={ROUTES.LANDING_PAGE}
                                    component={() => (
                                        <UserLandingPage 
                                           isLanding={isLanding}
                                           setIslanding={setIslanding}
                                        />
                                    )}
                                />
                            )} */}

                            <PrivateRoute
                                path={ROUTES.REQUESTS}
                                component={Requests}
                                name={name}
                                email={email}
                                invitations={invitations}
                                decrementReqCount={decrementReqCount}
                                updateReqCount={updateReqCount}
                                unrespondedCount={unrespondedReqCount}
                                isFull={isFull}
                                subscription={subscription}
                            />


                            {/* Error Fallback */}
                            <Route
                                component={() => <Error isAuth={isAuth} />}
                            />
                        </Switch>
                    </Suspense>
                    {/* Feedback Speed Dial Button */}
                    <FeedbackButton />
                </main>
            </div>
        </Router>
    )
}

export default App
