import axios from 'axios'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'
import { decrypt, getAge, newDecrypt } from '../../Patient/index'
import { get_patient_programs } from '../Patients'

/**
 * Fetch all patient-related data from Firebase for each patient id in @param patientsId.
 *
 * @param {String[]} patientsId - An array of patients' Firebase ids
 * @return {Promise<any>}
 */
const getInitialPatients = (patientsId, archivedIds, locked) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { accessToken } = await getAuthSession()
            const reqBody = {
                headers: { Authorization: `Bearer ${accessToken}` },
            }
            const patientIdsNoArchived = patientsId.filter(id=>!archivedIds.includes(id))
            const params = locked? { patientsId: patientsId }: {patientsId: patientIdsNoArchived}

            console.log(params)


            // const allRequests = patientsId.map((id) =>
            //     axios.get(`${baseUrl}/patients/${id}`, reqBody)
            // )
            // const responseArr = await axios.all(allRequests)
            
            
            const responseArr = await axios.post(`${baseUrl}/patients/getPatients`,params, reqBody)
            console.log('responseArr')
            console.log(responseArr)
            const validPatients = responseArr.data
            .filter((patient) => patient !== null && Object.values(patient)[0] !== null);
            // console.log('here')
            
            
            console.log('here patient1')
            let users = {}
            validPatients.forEach((patient, i) => {
                const id = Object.keys(patient)[0]
                console.log('here patient2')
                
                if (archivedIds && archivedIds.includes(id)) {
                    console.log('here patient3')
                    // console.log('forEach-Archive')
                    const data = Object.values(patient)[0]
                    const basicInfo = data?.basicInfo ? data?.basicInfo : {}
                    const subsInfo = data?.subsInfo ? data?.subsInfo : {}
                    users[id] = { basicInfo, subsInfo, status: 'archived' }
                    
                } else {
                    users[id] = Object.values(patient)[0]
                    console.log('here patient4')
                }
                
                users[id].id = id
                users[id].index = i
                
                // console.log('forEach')
                console.log('here patient5')
                let firstName
                if (users?.[id]?.basicInfo?.firstName) {
                    firstName = decrypt(users?.[id]?.basicInfo?.firstName)
                } else if (users?.[id]?.basicInfo?.name) {
                    firstName = newDecrypt(users?.[id]?.basicInfo?.name)
                } else {
                    firstName = 'None'
                }
                
                let age
                if (users?.[id]?.basicInfo?.birthDate) {
                    age = decrypt(users?.[id]?.basicInfo?.birthDate)
                } else if (users?.[id]?.basicInfo?.birth) {
                    age = newDecrypt(users?.[id]?.basicInfo?.birth)
                } else {
                    age = 'Not available'
                }
                
                if (age !== 'Not available') age = getAge(age)
                
                if (!users[id].basicInfo) users[id].basicInfo = {}
                
                users[id].basicInfo.firstName =
                firstName.charAt(0).toUpperCase() + firstName.slice(1)
                users[id].basicInfo.age = age
            })
 
            // console.log(users)
            resolve(users)
        } catch (err) {
            reject(`getPatients::error - ${JSON.stringify(err)}`)
        }
    })
}

export default getInitialPatients
