// Maximum number of patients for starter plan
export const MAX_PATIENT_COUNT = 1

// New SaaS Model:
export const plans = [
    {
        name: 'Starter',
        description: 'For coaches who own and run their own practice.',
        monthlyPrice: 5,
        yearlyPrice: 0,
        highlights: ['Unlimited client seat', 'Unlimited programs', 'Try your first client free'],
    },
    {
        planId: ['A-V1', 'A-V2'],
        name: 'Scale',
        description: 'For coaches who own and run their own practice.',
        monthlyPrice: 20,
        yearlyPrice: 4,
        highlights: [
            'Unlimited client seats',
            'Unlimited programs',
        ],
    },
    {
        planId: ['B-V1', 'B-V2'],
        name: `Course Creator Partner`,
        description: `For Health Experts who want to create content & 
        provide their clients with the best tools.`,
        monthlyPrice: 99,
        highlights: [
            'Unlimited Hosting on Pro Plans',
            'Unlimited Customers on all Plans',
            'UX-tested Landing Pages',
        ],
    },
    {
        planId: ['B-V1', 'B-V2'],
        name: 'Clinic Team',
        description: 'For clinicTeam and teams who work together.',
        monthlyPrice: 12,
        yearlyPrice: 10,
        highlights: [
            'Unlimited client seats',
            'Unlimited coaches',
            'Publish to marketplace',
        ],
    },
]

// New SaaS Model:
export const allFeatures = {
    general: [
        {
            name: 'Data & Uploads',
            details: 'Video content uploads, recommendations etc.',
            starter: 'Unlimited',
            practitionpreneur: 'Unlimited',
            clinicTeam: 'Unlimited',
            courseCreator: 'Unlimited',

        },
        {
            name: 'Clients',
            details: [
                'Number of client connections.',
                'Number of active clients seats for yearly subscriptions.',
            ],
            starter: 'Limited',
            practitionpreneur: 'Unlimited',
            clinicTeam: 'Unlimited',
            courseCreator: 'Unlimited',
        },
        {
            name: 'Archive Clients',
            details: [
                'Archive but not delete clients. You are not billed for archived clients.',
                'For yearly paid subscriptions, you do not need to archive clients because seats are assigned to specific clients.',
            ],
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator: 'N/A',
        },
    ],
    providerExperience:[
        {
            name: 'Realtime Client History via Comprehensive Dashboards',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:'With Hybrid'
        },
        {
            name: 'Charts with cycle phases overlay',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:'With Hybrid'
        },
        {
            name: '1:1s',
            starter: true,
            practitionpreneur: true,
            courseCreator:'With Hybrid'
        },
        {
            name: 'Fullscript Integration',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator: 'With Hybrid'
        },
        {
            name: 'Recommendations',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator: 'With Hybrid'
        },
        {
            name: 'Notes',
            starter: 'Unlimited',
            practitionpreneur: 'Unlimited',
            clinicTeam: true,
            courseCreator: 'N/A'
        },
        {
            name: 'File Uploads & Sharing',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator: 'With Hybrid'
        },
        {
            name: 'Forms',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator: 'With Hybrid'
        },
        {
            name: 'Video Calls',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator: 'With Hybrid'
        },
        {
            name: 'Messaging Chats',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator: 'With Hybrid'
        },

    ],
    program:[
        {
            name: 'Program Builder',
            starter: 'unlimited',
            practitionpreneur: 'unlimited',
            clinicTeam: true,
            courseCreator: 'N/A'

        },
        {
            name: 'Assign Programs to Clients',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator: 'N/A'

        },
        {
            name: 'Forms',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator: 'With Hybrid'

        }

    ],
    course:[
        {
            name: '1-time Payment Courses',
            starter: 'N/A',
            practitionpreneur: 'N/A',
            clinicTeam: 'Unlimited',
            courseCreator:'Unlimited'
        },
        {
            name: 'Membership Based Courses',
            starter: 'N/A',
            practitionpreneur: 'N/A',
            clinicTeam: 'Unlimited',
            courseCreator:'Unlimited'
        },
        {
            name: '1:1 Consultations + Courses (Hybrid Course)',
            starter: 'N/A',
            practitionpreneur: 'N/A',
            clinicTeam: 'Unlimited',
            courseCreator:'Unlimited'
        },
        {
            name: 'Course Previews',
            starter: 'N/A',
            practitionpreneur: 'N/A',
            clinicTeam: 'Unlimited',
            courseCreator: true
        },
        {
            name: 'Assign Courses to Clients',
            starter: 'N/A',
            practitionpreneur: 'N/A',
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
        {
            name: 'UX-tested Landing Pages for Course Builders',
            starter: 'N/A',
            practitionpreneur: 'N/A',
            clinicTeam: 'Unlimited',
            courseCreator: true
        },
        {
            name: 'Email Automation Integrations',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
        


    ],
    payment:[
        {
            name: 'Stripe Payments',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
    ],
    healthTracking:[
        {
            name: 'Wellness Journey',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
        {
            name: 'Connect to 1000+ apps',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
        {
            name: 'Connect with wearables (i.e. Apple Watch, Oura Ring, Tempdrop)',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
    ],
    cycle:[
        {
            name: 'Menstrual Cycle & Symptoms',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
        {
            name: 'Basal Body Temperature',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
        {
            name: 'Ovulation testing',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
        {
            name: 'Cervical Mucus (FEMM Method)',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
        
    ],
    fitness:[
        {
            name: 'Steps',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
        {
            name: 'Workouts',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
    ],
    sleep:[
        {
            name: 'Sleep & symptoms',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
    ],
    body:[
        {
            name: 'Skin condition (i.e. acne, eczema)',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
        {
            name: 'Pain',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
    ],
    nutrition:[
        {
            name: 'Blood Glucose',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
        {
            name: 'Meals & symptoms',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
        {
            name: 'Ketones',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
        {
            name: 'Hydration',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
        {
            name: 'Supplements + Fullscript Recommendations',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },

    ],
    mind:[
        {
            name: 'Mood',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
        {
            name: 'Meditation',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
        {
            name: 'Cognitive function',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
    ],
    patientCourse:[
        {
            name: 'Courses Player (Any Device)',
            starter: true,
            practitionpreneur: 'N/A',
            clinicTeam: 'Unlimited',
            courseCreator:true
        },
    ],
    patientProgram:[
        {
            name: 'Programs Player (Any Device)',
            starter: true,
            practitionpreneur: true,
            clinicTeam: 'Unlimited',
            courseCreator:'N/A'
        }
    ],
    features: [
        {
            name: 'Programs',
            details: 'Host your program with scheduled content on Flöka.',
            starter: true,
            practitionpreneur: 'Unlimited',
            clinicTeam: 'Unlimited',
            courseCreator:'N/A'
        },
        {
            name: 'Client Payments',
            details: 'Bill through Flöka x Stripe',
            starter: false,
            practitionpreneur: 'Stripe',
            clinicTeam: 'Stripe',
            courseCreator:'Stripe'
        },
        {
            name: 'Courses',
            details: 'Bill through Stripe',
            starter: 'N/A',
            practitionpreneur: 'N/A',
            clinicTeam: 'N/A',
            courseCreator:'Unlimited'
        },
        {
            name: 'Realtime Client History',
            details:
                'All data dashboards: sleep, fitness, cycle, sleep, nutrition, mind, etc.',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator:'With Hybrid'
        },
        {
            name: 'Insights',
            details: 'Our system helps create and display correlations beta.',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator:'With Hybrid'
        },
        {
            name: 'Chats',
            details: 'Chat with your client within the platform.',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator:'With Hybrid'
        },
        {
            name: 'Recommendations',
            details:
                'Send recommendation to your clients from within the platform.',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator:'With Hybrid'
        },
        {
            name: 'FullScript Integration',
            details:
                'Integrate with your Fullscript profile for seamless recommendation updates.',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator:'With Hybrid'
        },
        {
            name: 'Web + Mobile client Experience',
            details: 'clients can access the app on mobile (iOS/web) or web.',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator: false
        },
        {
            name: 'Custom Invites',
            details: 'Write custom invite messages to new clients.',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator: true
        },
        {
            name: 'Marketplace Listing',
            details: 'List your profile on the Flöka marketplace.',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator: false
        },
        {
            name: 'Connect to 1000+ Apps',
            details: 'Integration with 1k+ wellness apps and wearables',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator: true
        },
        {
            name: 'PDF Share',
            details: 'Upload PDFs to programs or chat.',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator:'With Hybrid'
        },
    ],
    admin: [
        {
            name: 'Admin Tools',
            details: 'Add people to your Flöka team on the platform.',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator:true
        },
    ],
    support: [
        {
            name: 'Premium Support',
            details:
                'Fast support - we aim to get back to you within 24 hours or faster.',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator:true
        },
        {
            name: 'Access New Features Early',
            details: 'Access premium features in beta.',
            starter: true,
            practitionpreneur: true,
            clinicTeam: true,
            courseCreator:true
        },
    ],
}
